$main-color: #343152;
$main-nd-color: #201e36;
$main-rd-color: #1e1d33;
$white-color: #fff;
$white7-color: #fffff7;
$white3-color: #fffff3;
$orange-color: #ff7600;
$grey-color: #7c7c7c;
$green-color: #50b1b1;
$boston-blue: #4999a4;
$blue-color: #2b95fc;
$success-color: rgba(35, 209, 96, 0.9);
$danger-color: rgba(255, 56, 20, 0.9);
$black: #000;
$outline-color: #7957d5;
$red-color: #ff0;

// walkthrough tema putih
$blue-title-text: #3f7790;
$grey-text-regular: #666;
$grey-text-placeholder: #8f8f8f;
$grey-form-input: #707070;
$btn-gradient1: #faaf40;
$btn-gradient2: #f05a28;
$btn-gradient3: #db3a26;
$grey-person: #f3f3f3;

$text-off-color: #454269;
$shadow-color: rgba(0, 0, 0, 0.16);
$black-opacity05: rgba(0, 0, 0, 0.5);
$black-opacity-card: rgba(0, 0, 0, 0.16);
$black-opacity01: rgba(0, 0, 0, 0.1);
$black30: hsl(0, 0%, 30%);
$black-opacity25: rgba(102, 102, 102, .25);
$shadow-footer: rgba(255, 255, 247, 0);
$transparent: rgba(0, 0, 0, 0);
$grey-opacity05: rgba(102, 102, 102, .5);

$background-scroll: rgba(110, 100, 143, 0.3);
$background-menus: #262261;
$active-scroll: rgba(110, 100, 143, 1);
$focus-color: rgba(150, 111, 54, 0.1);
$checkbox-focus: rgba(51, 187, 255, 1);

$chambray: #35547d;
$tradewind: #50b1b1;
$port-gore: #262261;
$wedgewood: #4793a0;

$text-color-active: #666;
$text-color-no-active: #999;
$text-color-no-active-2: #b5b5b5;

$card-hover-yellow: #fff5cb;
$scroll-bar: #e3e3e3;
$scroll-bar-child: #888;

$orange-color-label-modal: #ed5a28;
$notification-color: #ff3860;

$border-color: #ddd;

$orgchart-line: #666;

$white-color-opacity-30: rgba(255, 255, 255, 0.3);

$black-scroll: rgba(0, 0, 0, 0.9);
