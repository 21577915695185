$default: (
  "background": #fffff7,
  "font": #666,
  "label": #faaf40,
  "link": #50b1b1,
  "link_active": #50b1b1,
  "link_sidebar": #fff,
  "link_sidebar_active": #faaf40,
  "block": #fffff7,
  "block_active": #50b1b1,
  "main": #ed5a28,
  "main_1": #db3a26,
  "secondary": #50b1b1,
  "secondary_1": #262261
);

$themes: (
  "is-light": $default,
  "is-dark": (
    "background": #343152,
    "font": #fff,
    "label": map-get($default, "label"),
    "link": #fff,
    "link_active": #ff7600,
    "link_sidebar": #fff,
    "link_sidebar_active": #ff7600,
    "block": #454269,
    "block_active": #201e36,
    "main": map-get($default, "main"),
    "main_1": map-get($default, "main_1"),
    "secondary": #343152,
    "secondary_1": #201e36
  )
);

//fix colors
$white: #fff;

//decoration
$bold: bold; //should be 700 or something
$normal: normal; //should be 300 or something

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}
