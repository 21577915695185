@import "./colors";

@mixin shadow {
  box-shadow: 0 3px 6px 0 $shadow-color;
}

@mixin is-focus {
  border: solid lighten($outline-color, 25) !important;
  border-width: thin !important;
  outline: none !important;
}
