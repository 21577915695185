@import "./sass/colors";
@import "./sass/mixins";

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  overflow-y: hidden;
  height: 100vh;
  min-width: 1366px;
  color: $grey-text-regular;
}

//---------------------------------//
//  Global util classes
//---------------------------------//

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.shadow {
  @include shadow;
}

.bold {
  font-weight: bold;
}

.paper {
  @include shadow;
  border-radius: 5rem;
  padding: 3.2rem;
}

.hide {
  display: none;
}

.grey {
  color: $black-opacity05;
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline !important;
}

@media print {
  @page {
    size: landscape;
  }
}

.Toastify__toast-container {
  width: max-content !important;
}

//---------------------------------//
//  Global forms classes
//---------------------------------//

input[type="text"] {
  color: $grey-text-regular;
  font-size: 1.6rem;
}

textarea {
  resize: none;
  min-height: 32px;
  max-height: 220px;

  &:focus {
    @include is-focus;
    background-color: $focus-color !important;
  }
}

input:focus {
  @include is-focus;
  background-color: $focus-color !important;
}

select:focus {
  @include is-focus;
  background-color: $focus-color !important;
}

::placeholder {
  color: $grey-text-regular;
  font-size: 1.6rem;
  font-weight: bold;
  opacity: 0.5;
}

.is-focus:focus {
  @include is-focus;
  border-width: 3px !important;
}

.file-capsule {
  @include shadow;
  border-radius: 5rem;
  font-size: 1.2rem;
  padding: 0.5rem 1.6rem;
  display: inline-block;
  max-width: 16rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.capsule {
  min-width: calc(18rem - 3.2rem);
  padding: 1.2rem 2.4rem;
  text-align: center;
  border-radius: 5.4rem;
  // font-weight: bold;
  white-space: nowrap;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-size: 2.4rem;

  &.active {
    font-weight: bold;
    background-color: $green-color;
    color: $white-color;
  }

  &:not(.active) {
    @include shadow;
  }
}

.button {
  min-width: calc(18rem - 3.2rem);
  padding: 0 1.6rem;
  text-align: center;
  background-image: linear-gradient(
    $btn-gradient1,
    $btn-gradient2,
    $btn-gradient3
  );
  border-radius: 5.4rem;
  color: $white-color;
  font-weight: bold;
  white-space: nowrap;
  border: 0;
  font-family: Poppins;
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 5.4rem;

  &[disabled],
  &.alt {
    background-image: linear-gradient(
      to top,
      $grey-text-regular,
      $grey-text-regular
    );
  }

  &.small {
    font-size: 0.8rem;
    padding: 0 0.4rem;
    line-height: 1.8rem;
    min-width: 5rem;

    &.alt {
      background-image: linear-gradient(
        to top,
        $black-opacity25,
        $black-opacity25
      );
    }

    &.hollow {
      background-image: none;
      border: 1px $green-color solid;
      padding: 0 0.4rem;
      height: 1.6rem;
      color: $green-color;
      display: flex;
      justify-content: center;
      align-items: center;

      &.alt {
        border-color: $black-opacity25;
        color: $black-opacity25;
      }
    }
  }

  &.medium {
    padding: 5px 5px 5px 0;
    text-align: center;
  }
}

//---------------------------------//
//  Move to specific compnent
//---------------------------------//

.button-footer {
  background-image: linear-gradient(
    $shadow-footer,
    $white7-color,
    $white7-color
  );
  bottom: 0;
  padding-top: 0.8rem;
  font-weight: Poppins;
  padding-bottom: 3.2rem;

  .content {
    justify-content: flex-end;
  }

  .action-buttons,
  .buttons {
    display: flex;
    flex-shrink: 1;
    justify-content: flex-end;
  }

  .action-buttons {
    flex-grow: 1;
  }

  .button {
    margin-left: 2rem;
  }
}

.data-table {
  width: 100%;
  border-collapse: collapse;

  tr {
    height: 62px;
  }

  tbody {
    tr {
      &:hover {
        & td {
          background-color: $card-hover-yellow;
        }

        & td:first-child {
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
        }

        & td:last-child {
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
        }
      }

      &.less-radius:hover {
        & td {
          background-color: $card-hover-yellow;
        }

        & td:first-child {
          border-top-left-radius: 24px;
          border-bottom-left-radius: 24px;
        }

        & td:last-child {
          border-top-right-radius: 24px;
          border-bottom-right-radius: 24px;
        }
      }

      &:hover .actions {
        opacity: 1;
      }

      .actions {
        opacity: 0;
      }

      .status {
        display: flex;
        flex-direction: row;
        padding: 0 0 0 3.1rem;
        height: 100px;
        justify-content: space-between;
      }

      &:hover .status {
        background-color: $green-color;
        border-radius: 5rem;
        color: $white-color;

        &.color-y {
          background-color: $btn-gradient1;
        }

        &.color-o {
          background-color: $orange-color-label-modal;
        }

        &.color-b {
          background-color: $blue-color;
        }

        &.color-g {
          background-color: $success-color;
        }
      }
    }

    &.double tr {
      height: 92px;
    }

    &.wrap-normal td {
      white-space: normal; 
    }
  }

  td {
    padding: 0 1.6rem;

    span {
      font-size: 1.2rem;
      opacity: 1;
    }
  }

  th {
    padding: 0 1.6rem;
    font-weight: bold;
    border-bottom: 1px solid $grey-form-input;
    text-align: left;
  }

  p,
  span,
  th,
  td {
    margin: 0;
    white-space: nowrap;
  }
}
//---------------------------------//
//  Text transform
//---------------------------------//

.text {
  &.allcaps {
    text-transform: uppercase;
  }

  &.lowcase {
    text-transform: lowercase;
  }

  &.capital {
    text-transform: capitalize;
  }
}

//---------------------------------//
//  Bulma Overide
//---------------------------------//

.columns.is-8r {
  & > .column {
    padding-left: 4rem;

    &:first-child {
      padding-left: 0;
    }
  }
}

.component-upload {
  position: relative;
  border: 1px solid $white-color;
  border-radius: 50%;
  width: 18.5rem;
  height: 18.5rem;
  margin: auto;
  padding: 0.2rem;

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    &[alt="edit"] {
      position: absolute;
      top: 0;
      right: 0;
      display: none;
    }

    &[alt="foto"] {
      position: relative;
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
  }

  input {
    cursor: pointer;

    &:hover ~ img[alt="edit"] {
      display: inline;
      z-index: 2;
    }
  }

  input[type="file"] {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
  }
}

.css-1u9mnw6-placeholder {
  color: $grey-text-regular;
  font-size: 1.6rem;
  font-weight: bold;
  opacity: 0.5;
}
//---------------------------------//
//  Global flex classes
//---------------------------------//

.flex {
  display: flex;

  &.items-center {
    align-items: center;
  }

  &.items-top {
    align-items: flex-start;
  }
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

//---------------------------------//
//  Global distance classes
//---------------------------------//

$items: "top", "left", "right", "bottom";

@for $i from 1 through 20 {
  @each $item in $items {
    .margin-#{$item}-#{$i*4} {
      margin-#{$item}: #{$i * 4/10}rem;
    }

    .padding-#{$item}-#{$i*4} {
      padding-#{$item}: #{$i * 4/10}rem;
    }
  }

  .padding-#{$i*4} {
    padding: #{$i * 4/10}rem;
  }

  .margin-#{$i*4} {
    margin: #{$i * 4/10}rem;
  }
}

//---------------------------------//
//  Browser fix
//---------------------------------//

a {
  text-decoration: none;
  color: $green-color;
}

a:focus,
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  text-decoration: none;
  outline: none;
  cursor: pointer;
}

// * {
//   -webkit-tap-highlight-color: $transparent;
//   -webkit-touch-callout: none;
//   -webkit-user-select: none;
//   -khtml-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   outline: none;
// }
